import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_software_engineer.svg';

function ServicesDesignPage() {

    return <ContentPage>
        <h1>Analysis and Design</h1>
        <p>Our projects always begin by getting to understand your business and the key factors that influence your 
            success. Without that knowledge, any application development will be based on assumptions that are likely 
            to be wrong.</p>
        <PageImage image={image} alt='Analysis and Design' leftAlign={true} />
        <p>We have deep experience of many industries, but we know that even businesses in the same market can have 
            very different approaches and priorities.</p>
        <p>Coralpeak Systems will develop the design for your application that best meets your objectives. We take care 
            of the technical details to allow you to focus on what will make the difference for your business. We will 
            recommend or suggest any alternatives that may open unforeseen opportunities, but you always remain in 
            control of your project.</p>
        <p>If another partner will develop the programs for you, Coralpeak Systems can deliver technical documentation 
            and designs for that the partner, which will ensure that the application is built to your business 
            requirements.</p>
    </ContentPage>

}

export default ServicesDesignPage;